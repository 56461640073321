const JS_HOST_REGEXES = {
  prod: /(^https:\/\/.*chargify.(test|com)\/.*)chargify\.(unmin\.)*js(\?.*)?$/,
  dev: /(^https?:\/\/localhost:4000\/dist\/chargify\/)chargify\.js(\?.*)?$/,
  test: /(^https:\/\/js\.chargify\.test\/dist\/chargify\/)chargify\.js(\?.*)?$/,
  staging: /(^https:\/\/js\.staging-chargify\.com\/(v\/.*|latest)\/)chargify\.js(\?.*)?$/,
};

const SERVER_HOST_DOMAINS = [
  'chargify.com',
  'staging-chargify.com',
  'qa-chargify.com',
  'rock.staging-chargify.com',
  'paper.staging-chargify.com',
  'scissors.staging-chargify.com',
  'chargify.test',
  'test-chargify.com',
  'lvh.me',
  'maxio.com',
  'ebilling.maxio.com',
  'ebilling.staging.maxio.com'
];

const PAYMENTS_SERVICE_HOSTS = [
  'https://payments.test',
  'https://payments-svr.staging.maxio.com',
  'https://payments-svr.maxio.com',
];

// Only these are allowed to access the "Chargify._internal" fields
const INTERNAL_ALLOWED_CLIENT_HOST_DOMAINS = [
  'chargifypay.com',
  'staging-chargifypay.com',
  'qa-chargifypay.com',
  'chargifypay.test',
  'test-chargifypay.com',
  'localhost',
  'billing.maxiopay.com',
  'staging-billing.maxiopay.com',
  'qa-billing.maxiopay.com',
  'billing.maxiopay.test',
  'test-billing.maxiopay.com',
  'ebilling.maxiopay.com',
  'portal.test',
  'staging-portal.com',
  'billingportal.com'
];

const matchJsHost = src =>
  src.match(JS_HOST_REGEXES.prod) ||
    src.match(JS_HOST_REGEXES.dev) ||
    src.match(JS_HOST_REGEXES.test) ||
    src.match(JS_HOST_REGEXES.staging);

const fetchDomain = (url) => {
  const urlParts = url.split('.');
  const domain = urlParts.includes('maxiopay') ? urlParts.slice(-3).join('.') : urlParts.slice(-2).join('.');
  const withoutPort = domain.replace(/:\d{4,}$/, '');

  return withoutPort;
};

const validateServerHost = url =>
  PAYMENTS_SERVICE_HOSTS.includes(url) || SERVER_HOST_DOMAINS.includes(fetchDomain(url));

const validateInternalAllowedClientHost = () => {
  const domain = fetchDomain(window.location.hostname);

  return INTERNAL_ALLOWED_CLIENT_HOST_DOMAINS.includes(domain);
};

export default {
  matchJsHost,
  validateServerHost,
  validateInternalAllowedClientHost,
};
